@tailwind base;
@tailwind components;
@tailwind utilities;


/* Global reset and box-sizing settings */
*,
*::before,
*::after {
  box-sizing: inherit;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* Base HTML and body styles */
html {
  box-sizing: border-box;
  scroll-snap-type: y mandatory;
  margin: 0;
  padding: 0;
}

body {
  background-color: #141414; /* Black background */
  font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande','Lucida Sans Unicode', Geneva, Verdana, sans-serif;
}

/* No scrollbar utility */
.no-scrollbar::-webkit-scrollbar {
  display: none !important;
}

.no-scrollbar {
  -ms-overflow-style: none; /* IE 10+ */
  scrollbar-width: none; /* Firefox */
}

/* Dark theme scrollbar styles */
::-webkit-scrollbar {
  width: 14px;
  background-color: #2d2d2d; /* Dark background for track */
}

::-webkit-scrollbar-track {
  background-color: #2d2d2d; /* Match track background */
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background-color: #555555; /* Dark grey thumb */
  border-radius: 8px;
  border: 2px solid #2d2d2d; /* Blend thumb with track */
}

::-webkit-scrollbar-thumb:hover {
  background-color: #777777; /* Lighten on hover */
}

::-webkit-scrollbar-thumb:active {
  background-color: #999999; /* Lighter on active */
}

::-webkit-scrollbar-button:single-button {
  background-color: #444444; /* Slightly lighter for buttons */
  display: block;
  background-repeat: no-repeat;
  background-position: center;
  height: 16px;
  border-radius: 50%;
}

/* Scrollbar arrow icons */
::-webkit-scrollbar-button:single-button:decrement {
  background-image: url('data:image/svg+xml,%3Csvg xmlns%3D%22http://www.w3.org/2000/svg%22 viewBox%3D%220 0 24 24%22 fill%3D%22%23ffffff%22%3E%3Cpath d%3D%22M12 8l-6 6h12z%22/%3E%3C/svg%3E'); /* White up arrow */
}

::-webkit-scrollbar-button:single-button:increment {
  background-image: url('data:image/svg+xml,%3Csvg xmlns%3D%22http://www.w3.org/2000/svg%22 viewBox%3D%220 0 24 24%22 fill%3D%22%23ffffff%22%3E%3Cpath d%3D%22M12 16l6-6H6z%22/%3E%3C/svg%3E'); /* White down arrow */
}

::-webkit-scrollbar-corner {
  background-color: #2d2d2d; /* Match track background */
}

/* OTP input field styling */
.otp-input-field {
  width: 100%;
  text-align: center;
  align-items: center;
  justify-content: center;
  font-size: 1.5rem;
  padding: 0.5rem;
  border: 1px solid #ccc;
  border-radius: 0.25rem;
  margin: 0.25rem;
}

.otp-input {
  display: flex;
  justify-content: space-between;
  /* justify-content: center; */
  /* gap: 10px; */
}




.otp-input input {
  background-color: rgb(67, 67, 67); /* Dark background */
  border-radius: 2px; /* Rounded edges */
  border: none !important; /* Remove borders */
  outline: none !important; /* Remove outline */
  color: white !important; /* White text */
  text-align: center !important; /* Center the text */
  box-shadow: none !important; /* Remove shadow */
}

.otp-input input:focus {
  border: none !important; /* Ensure no border on focus */
  outline: none !important; /* Ensure no outline on focus */
  box-shadow: none !important; /* Ensure no shadow on focus */
}

/* Autofill styling for browsers */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus {
  -webkit-text-fill-color: white; /* Keep autofill text white */
  -webkit-box-shadow: 0 0 0px 1000px black inset; /* Remove autofill background */
  transition: background-color 5000s ease-in-out 0s;
}

/* Full screen video container */
.video-container {
  width: 100vw;
  height: 100vh;
  background-color: black;
  z-index: 9999;
}

/* Snap feature for each video card */
.snap-start {
  scroll-snap-align: start;
  scroll-snap-stop: always;
}

/* Ensure comment breaks */
.break-comment {
  word-break: break-all;
}

/* Reels video card styling */
.reels-video-card {
  width: 100%;
  height: 100%;
  object-fit: cover;
  -webkit-object-fit: cover;
  max-width: 100%;
  max-height: 100%;
}

/* Reels section container */
.container {
  overflow-y: scroll;
  scroll-snap-type: y mandatory;
  height: 100vh;
  max-width: 400px;
  -ms-overflow-style: none;
  scrollbar-width: none; /* Hide scrollbar in Firefox */
  -webkit-overflow-scrolling: touch; /* Smooth scrolling on iOS */
  justify-content: center;
  align-items: center;
}

.container .ReelsVideoCard {
  scroll-snap-align: start;
}

.container::-webkit-scrollbar {
  display: none; /* Hide scrollbar in WebKit-based browsers */
}

@media (min-width: 768px) {
  .container {
    max-width: 400px; /* Constrain width for tablets and larger screens */
  }
}

@media (max-width: 768px) {
  .container {
    width: 100vw; /* Full width for mobile */
    height: calc(100vh - 50px); /* Adjust height for mobile */
  }
}

/* Date picker styling */
input[type="date"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: #333;
  color: #fff;
  border: 1px solid #666;
  padding: 6px;
  border-radius: 4px;
  cursor: pointer;
}

input[type="date"]::-webkit-calendar-picker-indicator {
  filter: invert(1); /* Invert color to make it visible on dark background */
}

input[type="date"]:focus {
  border-color: #46c7f1; /* Light blue border on focus */
  outline: none;
}

/* Loader styles */
.loader,
.loader::after {
  width: 100%;
  height: 5px;
  background-image: linear-gradient(45deg, rgba(63,94,251,1) 0%, rgba(252,70,107,1) 100%);
  position: relative;
  z-index: 1;
  animation: ani 10s linear infinite;
  background-size: 500%;
}

.loader::after {
  content: "";
  filter: blur(400px);
  display: block;
  position: relative;
  z-index: -1;
  animation: ani 10s linear infinite;
}

@keyframes ani {
  from {
    background-position: 0 0;
  }
  to {
    background-position: 500% 0;
  }
}

.loader::before {
  content: "";
  width: 80px;
  height: 80px;
  background: var(--bg-color);
  border-radius: 50%;
  position: absolute;
  z-index: 2;
  top: 10px;
  left: 10px;
}
